import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Link } from "gatsby"

const SecondPage = () => (
  <Layout>
    <SEO
      title="Additions, Remodels and Renovations Additions"
      description="When it comes to home additions, remodels, renovations, and construction documents - JKA Design is the architectural firm to partner with."
    />
    <div class="page-headline">
      <div class="container">
        <div class="section-heading text-center">
          <h6 class="font-weight-bold text-uppercase text-white-50 flair">
            Home Additions and Remodels in Colorado
          </h6>
          <h1>
            <strong>
              Home Additions, Remodels, And Renovations in Colorado
            </strong>
          </h1>
        </div>
      </div>
    </div>
    <div class="page-content">
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col-md-12 mb-4">
            <h4>
              A select few of our addition, remodel and renovation projects
            </h4>
            <div class="row justify-content-around py-5">
              <div class="col-md-4 mb-5">
                <div class="h-100 services-inner shadow-sm">
                  <Link to="/portfolio-item/boulder-mountain-cabin">
                    <div
                      class="services-image"
                      style={{
                        backgroundImage: `url(
													"https://cdn.sanity.io/images/2gff8qxw/production/d9bc7291ce6a550fd4967d1774ba87f64df4a32a-1030x818.jpg"
												)`,
                      }}
                    />
                    <div class="services-content py-3 px-4">
                      <h4>Boulder Mountain Cabin</h4>
                    </div>
                  </Link>
                </div>
              </div>
              <div class="col-md-4 mb-5">
                <div class="h-100 services-inner shadow-sm">
                  <Link to="/portfolio-item/contemporary-entry-and-master-addition">
                    <div
                      class="services-image"
                      style={{
                        backgroundImage: `url(
													"https://cdn.sanity.io/images/2gff8qxw/production/99d4fd016b9d3bc9cbc41710eb33b6300ec65546-1030x773.jpg"
												)`,
                      }}
                    />
                    <div class="services-content py-3 px-4">
                      <h4>Contemporary Entry and Master Addition</h4>
                    </div>
                  </Link>
                </div>
              </div>
              <div class="col-md-4 mb-5">
                <div class="h-100 services-inner shadow-sm">
                  <Link to="/portfolio-item/large-kitchen-makeover">
                    <div
                      class="services-image"
                      style={{
                        backgroundImage: `url(
													"https://cdn.sanity.io/images/2gff8qxw/production/c129a1d3fbe07e969b86d36fa8be77b1a7e001d8-861x600.jpg"
												)`,
                      }}
                    />
                    <div class="services-content py-3 px-4">
                      <h4>Large Kitchen Makeover</h4>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <h4>
              Jeffry K. Abrams, Architect is a full-service architectural firm
              offering the following services for additions, remodels and
              renovations:
            </h4>
            <div class="row justify-content-around py-5">
              <div class="col-md-6">
                <ul className="services-list">
                  <li>Planning &amp; Zoning Consultation and Advising</li>
                  <li>Code research</li>
                  <li>Site Planning</li>
                  <li>Architectural Design</li>
                  <li>Preliminary Design</li>
                  <li>Schematic Design</li>
                  <li>Custom and Luxury Residential Design</li>
                  <li>Residential Addition – Design and consultation</li>
                  <li>Remodeling and Renovation Design</li>
                  <li>Construction Documents</li>
                </ul>
              </div>
              <div class="col-md-6">
                <ul className="services-list">
                  <li>Working Drawings</li>
                  <li>Construction Detailing</li>
                  <li>As Built Measuring and Drawings</li>
                  <li>Energy Consulting</li>
                  <li>Passive Solar Consultation and Design</li>
                  <li>Bidding or Negotiation</li>
                  <li>Construction Contract Consultancy</li>
                  <li>Site Observation and Advising</li>
                  <li>Design Review Consultation</li>
                  <li>Forensics</li>
                </ul>
              </div>
            </div>
            <div class="row justify-content-around py-5">
              <div class="col-md-6 m-auto">
                <iframe
                  src="https://www.youtube.com/embed/SMqsD5btktA?rel=0&amp;showinfo=0&amp;start=9&amp;wmode=opaque"
                  width="100%"
                  height="315"
                  frameborder="0"
                  title="What does an Architect do?"
                  allowfullscreen="allowfullscreen"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="review-snippet padding-50 text-center text-white">
      <div className="container">
        <p className="text-white">
          <i className="fa fa-quote-left mr-3" />
          <em>
            “Jeff Abrams designed a complete remodel and addition to our
            kitchen. He consulted with my husband and me, listened to us
            describe our needs, and provided us with a set of drawings which
            solved all our problems and increased our space. Our old kitchen was
            completely gutted, all new appliances and cabinets and counters were
            replaced. An old pantry was eliminated and a newer one added, as
            well as an expanded eating area.
            <br />
            Jeff’s creativity was exemplary, and he was responsive to all the
            changes and new ideas we came up with as the project was ongoing. I
            would highly recommend Jeff, both for new design, and remodel.”
          </em>
          <i className="fa fa-quote-right ml-3" />
        </p>
      </div>
      <em>–Susan L., Client</em>
    </div>
  </Layout>
)

export default SecondPage
